import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import classNames from 'classnames';
import { PrismicNextImage } from '@prismicio/next';
import { useEffect, useState } from 'react';
import styles from './IconsGrid.module.scss';
/**
 * Props for `IconsGrid`.
 */
export type IconsGridProps = SliceComponentProps<Content.IconsGridSlice>;

/**
 * Component for "IconsGrid" Slices.
 */
const IconsGrid = ({ slice }: IconsGridProps): JSX.Element => {
  const { tag_text, background_color } = slice.primary;
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    function isDivInViewAnd74pxFromTop() {
      const div = document.getElementById('icons-grid-slice-section');
      if (div) {
        const rect = div?.getBoundingClientRect();
        const is74pxFromTop = rect.top < 94;
        const verticalLine = document.getElementById('vertical-line-icons-grid-slice');
        if (is74pxFromTop) {
          verticalLine.classList.add(styles.activeLine);
        } else {
          verticalLine.classList.remove(styles.activeLine);
        }
      }
    }

    window.addEventListener('scroll', isDivInViewAnd74pxFromTop);

    return () => {
      window.removeEventListener('backgroundColorChange', () => {});
      window.removeEventListener('scroll', () => {});
    };
  }, []);
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={classNames(
        styles.root,
        'wide-container',
        styles[bg || background_color],
        styles[slice.variation],
        'color-changing-section',
      )}
      data-background={background_color}
      id="icons-grid-slice-section"
    >
      <div className={classNames(styles.content)}>
        <div className={styles.iconsContainer}>
          <div className={styles.body}>
            <div className={styles.tagText}>{tag_text}</div>
            <div className={styles.verticalLine}></div>
            {/* <div className={styles.activeLine}></div> */}
            <div id="vertical-line-icons-grid-slice"></div>
            <div className={classNames(styles.items, styles.active)}>
              {slice.items.map(item => (
                <div className={styles.card} key={item.heading}>
                  <PrismicNextImage className={styles.icon} field={item.icon} />
                  <div className={styles.heading}>{item.heading}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IconsGrid;
